import React, { useState, useEffect, useRef } from "react";
import ProgressBar from "./Progress.js";

import Image1 from "../images/about1.webp";
import Image2 from "../images/about2.webp";

export default function About(){

    const [offsetY, setOffsetY] = useState(0);

    const [parallaxY, setParallaxY] = useState(0);

    const parallaxRef = useRef(null);

    const handleScroll = () => {

        setOffsetY(window.scrollY);

        if(parallaxRef.current){

            const rect = parallaxRef.current.getBoundingClientRect();

            const width = window.innerWidth;

            let factor;

            if(width < 500){

                factor = 1;

            } else if(width < 700){

                factor = 200;

            } else if(width < 1000){

                factor = 700;

            } else if(width < 1400){

                factor = 1200;

            } else {

                factor = 2400;

            }

            const windowHeight = window.innerHeight;
      
            const relativeScroll = (rect.top - windowHeight) / windowHeight;
            
            setParallaxY(relativeScroll * factor);

        }

    };
    
    useEffect(() => {

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);

    }, []);

    return (
        <>
            <div className="page">
                <div id="about1-outer-container">
                    <div id="about1-outer-wrapper">
                        <div id="about1-left-wrapper">
                            <h2 className="about1-sub-heading">Cześć, nazywam się</h2>
                            <h1 id="about1-heading">Paweł Pokrywka</h1>
                            <h2 className="about1-sub-heading">i zajmuję się projektowaniem</h2>
                        </div>
                        <div id="about1-right-wrapper">
                            <div id="about-intro-photo-wrapper" style={{ backgroundPositionY: `${offsetY * (window.innerWidth < 768 ? 0.5 : 0.7)}px` }}>
                                <img id="about-intro-photo" src={Image1} alt="about-me"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="about2-outer-container">
                    <div className="about2-outer-wrapper">
                        <div className="about2-left-wrapper">
                            <h2 className="about2-left-heading">O mnie</h2>
                            <span className="about2-underline"></span>
                        </div>
                        <div className="about2-right-wrapper">
                            <h3 className="about2-right-heading">Człowiek, który kocha tworzyć</h3>
                            <p className="about2-right-description">Niezależnie czy chodzi o stronę internetową czy też grafikę komputerową zawsze uwielbiam tworzyć nowe treści, inspirować się i napędzać do działania. Ciekawe pomysły i oryginalne rozwiązania dają mi mnóstwo satysfakcji, a moment gdy wszystko to nabiera realnych kształtów jest niezwykle przyjemny.</p>
                            <p className="about2-right-description">Cenię sobie wychodzenie poza schemat i wolność w tworzeniu, stąd moje realizacje są oryginalne i nieszablonowe. Na pierwszym miejscu stawiam satysfakcję klienta, więc zawszę dopasuję projekt do oczekiwań.</p>
                        </div>
                    </div>
                    <div className="about2-outer-wrapper">
                        <div className="about2-left-wrapper">
                            <h2 className="about2-left-heading">Jaki jestem</h2>
                            <span className="about2-underline"></span>
                        </div>
                        <div className="about2-right-wrapper">
                            <h3 className="about2-right-heading">Systematyczny, skrupulatny i szczegółowy</h3>
                            <p className="about2-right-description">Przy tworzeniu cechuje mnie metodycznosć i dbałość o detale. Lubię, by projekt był dopasowany w 100% i cieszył zarówno mojego klienta jak i mnie. Zawsze dbam o terminowe wykonanie powierzonego zadania, nie obiecuję rzeczy bez pokrycia i nie podejmuję zleceń, których nie mogę zrealizować.</p>
                            <p className="about2-right-description">W swojej pracy skupiam się na konkretach, a klientowi przedstawiam różne opcje realizacji jego projektu i pozostawiam pełną swobodę w wyborze optymalnej formy.</p>
                        </div>
                    </div>
                    <div className="about2-outer-wrapper">
                        <div className="about2-left-wrapper">
                            <h2 className="about2-left-heading">Co lubię</h2>
                            <span className="about2-underline"></span>
                        </div>
                        <div className="about2-right-wrapper">
                            <h3 className="about2-right-heading">Komputery, sport i koty</h3>
                            <p className="about2-right-description">Pasjonuję się komputerami i wszystkim co z nimi związane - sprzętem, oprogramowaniem, multimediami czy też grami. Zdalnie mogę udzielić wsparcia przy różnego rodzaju problemach natury informatycznej, a jeśli mieszkasz w okolicach Poznania mogę Ci pomóc przy instalacji systemu operacyjnego, a także archiwizowaniu danych.</p>
                            <p className="about2-right-description">Ponadto interesuję się sportem - głównie piłką nożną, tenisem ziemnym, a także skokami narciarskimi. Bardzo lubię zwierzęta, a szczególną sympatią dażę koty i wszystkich ich większych kuzynów.</p>
                        </div>
                    </div>
                    <div className="about2-outer-wrapper">
                        <div className="about2-left-wrapper">
                            <h2 className="about2-left-heading">Moje umiejętności</h2>
                            <span className="about2-underline"></span>
                        </div>
                        <div id="about-animated" className="about2-right-wrapper">
                            <div className="progress-bars-outer-wrapper">
                                <div className="progress-bar-wrapper">
                                    <p className="progress-bar-label">HTML</p>
                                    <ProgressBar percentage={95}/>
                                </div>
                                <div className="progress-bar-wrapper">
                                    <p className="progress-bar-label">CSS</p>
                                    <ProgressBar percentage={95}/>
                                </div>
                                <div className="progress-bar-wrapper">
                                    <p className="progress-bar-label">REACT JS</p>
                                    <ProgressBar percentage={75}/>
                                </div>
                                <div className="progress-bar-wrapper">
                                    <p className="progress-bar-label">PHP</p>
                                    <ProgressBar percentage={85}/>
                                </div>
                                <div className="progress-bar-wrapper">
                                    <p className="progress-bar-label">SQL</p>
                                    <ProgressBar percentage={70}/>
                                </div>
                                <div className="progress-bar-wrapper">
                                    <p className="progress-bar-label">GRAFIKA</p>
                                    <ProgressBar percentage={90}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="about3-outer-container">
                    <div id="about3-outer-wrapper">
                        <div ref={parallaxRef} id="about3-photo-container" style={{ backgroundPositionY: `${-[-parallaxY] * 0.05}px` }}>
                            <img id="about3-image" src={Image2} alt="handshake"/>
                            <div id="about3-heading-wrapper">
                                <h3 id="about3-heading">Do zobaczenia<br></br>przy projekcie</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};