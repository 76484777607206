import React from 'react';
import Signature from '../images/signature.webp';
import { Link } from 'react-router-dom';

export default function Footer() {

    return (
        <>
            <div id="footer-divider">
            </div>
            <div id="footer-outer-container">
                <div id="footer-outer-wrapper">
                    <div id="footer-left-wrapper">
                        <div id="footer-left-inside">
                            <p id="footer-header">Porozmawiajmy</p>
                            <p id="footer-text">Każdy projekt zaczyna się od rozmowy.<br></br>Z radością omówię Twoje plany i pomogę<br></br>Ci je urzeczywistnić.</p>
                            <div id="footer-signature-wrapper">
                                <img id="footer-signature" src={Signature} alt="signature"/>
                            </div>
                        </div>
                    </div>
                    <div id="footer-right-wrapper">
                        <div className="footer-links-wrapper">
                            <div className="footer-link-left">
                                <p className="footer-link-description">Email</p>
                            </div>
                            <div className="footer-link-right">
                                <a className="footer-link" href="mailto:serwis@pp-start.pl">serwis@pp-start.pl</a>
                            </div>
                        </div>
                        <div className="footer-links-wrapper">
                            <div className="footer-link-left">
                                <p className="footer-link-description">Telefon</p>
                            </div>
                            <div className="footer-link-right">
                                <a className="footer-link" href="tel:662890561">662-890-561</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="footer-copyright-wrapper">
                    <p id="policy-link-paragraph"><Link id="policy-link" to="/polityka-prywatności">Polityka prywatności</Link></p>
                    <p id="footer-copyright"><span>&copy;</span>{new Date().getFullYear()} - PP Start</p>
                </div>
            </div>
        </>
    )
}