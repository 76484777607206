import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { About, Common, Contact, Main, Portfolio, Privacy, Services } from './index';

function ScrollToTop() {

    const { pathname } = useLocation();

    useEffect(() => {

        window.scrollTo(0, 0);
        
    }, [pathname]);

    return null;

}

function App() {

    useEffect(() => {
        document.title = 'PP Start';
    }, []);

    return (
        <>
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    <Route path='/' element={<Common />}>
                        <Route index element={<Main />} />
                        <Route path='/kontakt' element={<Contact/>} />
                        <Route path='/o-mnie' element={<About />} />
                        <Route path='/polityka-prywatności' element={<Privacy />} />
                        <Route path='/portfolio' element={<Portfolio />} />
                        <Route path='/uslugi' element={<Services />} />
                        <Route path='*' element={<Navigate to='/' />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    );

}

export default App;