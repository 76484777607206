import React, { useState, useEffect } from "react";

import Signature from "../images/signature.webp";
import IntroImage1 from "../images/work1.webp";
import IntroImage2 from "../images/work2.webp";
import IntroImage3 from "../images/work3.webp";

import ApplicationComposition1 from "../images/application-image1.webp";
import ApplicationComposition2 from "../images/application-image2.webp";
import ApplicationComposition3 from "../images/application-image3.webp";

import WebsiteComposition1 from "../images/website-composition1.webp";
import WebsiteComposition2 from "../images/website-composition2.webp";
import WebsiteComposition3 from "../images/website-composition3.webp";
import WebsiteComposition4 from "../images/website-composition4.webp";

import GraphicsComposition1 from "../images/graphics-composition1.webp";
import GraphicsComposition2 from "../images/graphics-composition2.webp";
import GraphicsComposition3 from "../images/graphics-composition3.webp";
import GraphicsComposition4 from "../images/graphics-composition4.gif";
import GraphicsComposition5 from "../images/graphics-composition5.gif";
import GraphicsComposition6 from "../images/graphics-composition6.gif";

import CustomerLogo1 from "../images/customer-logo5.webp";
import CustomerLogo2 from "../images/customer-logo6.webp";
import CustomerLogo3 from "../images/customer-logo2.webp";
import CustomerLogo4 from "../images/customer-logo9.webp";
import CustomerLogo5 from "../images/customer-logo3.webp";
import CustomerLogo6 from "../images/customer-logo7.webp";
import CustomerLogo7 from "../images/customer-logo10.webp";
import CustomerLogo8 from "../images/customer-logo8.webp";
import CustomerLogo9 from "../images/customer-logo12.webp";
import CustomerLogo10 from "../images/customer-logo11.webp";

export default function Portfolio(){

    const [offsetY, setOffsetY] = useState(0);

    const handleScroll = () => {

        setOffsetY(window.scrollY);

    };
    
    useEffect(() => {

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);

    }, []);

    const [isInView, setIsInView] = useState({left: false, right: false});

    

    useEffect(() => {

        const element1 = document.getElementById("work3-text-left");

        const element2 = document.getElementById("work3-text-right");

        const observer = new IntersectionObserver(

            ([entry]) => {

                if(entry){

                    const attributes = entry.target.attributes;

                    const name = attributes.name.value;

                    setIsInView(isInView => {
                        return {
                            ...isInView,
                            [name]: entry.isIntersecting
                        }
                    });

                }
                
            }, { threshold: 0.1, }
        );

        if(element1){

            observer.observe(element1);

        }

        if(element2){

            observer.observe(element2);

        }

    }, []);

    return (
        <div className="page">
            <div id="work1-outer-container">
                <div id="work1-outer-wrapper">
                    <div id="work1-left-wrapper">
                        <div id="work1-left-inner-wrapper">
                            <h1 id="work1-heading">Witaj!</h1>
                            <h2 id="work1-sub-heading">Cieszę się, że interesują Cię moje prace i chcesz wziąć pod lupę moje portfolio.</h2>
                            <img id="work1-signature" src={Signature} alt="signature"/>
                        </div>
                    </div>
                    <div id="work1-right-wrapper">
                        <div id="work1-intro-photo-wrapper">
                            <img id="work1-intro-photo1" src={IntroImage1} alt="intro"/>
                            <div id="work1-intro-photo-inner-wrapper" style={{ backgroundPositionY: `${offsetY * 0.1}px` }}>
                                <img id="work1-intro-photo2" src={IntroImage2} alt="intro"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wave-container"></div>
            <div id="work2-outer-container">
                <div id="work2-outer-wrapper">
                    <div className="work2-heading-wrapper">
                        <h2 className="work2-heading">Aplikacje</h2>
                    </div>
                    <div className="work2-content-wrapper">
                        <div className="work2-left-wrapper">
                            <img className="work2-left-image" src={ApplicationComposition1} alt="application graphic"/>
                        </div>
                        <div className="work2-right-wrapper">
                            <div className="work2-right-heading-wrapper">
                                <h3 className="work2-right-heading">Rubika Webcam</h3>
                            </div>
                            <div className="work2-right-description-wrapper">
                                <p className="work2-right-description">Aplikacja służąca do zarządzania rozmieszczeniem kamer służących do prowadzenia badań ruchu. Umożliwia tworzenie pinezek na zintegrowanej mapie google i pozyskiwanie danych z montowanych kamer. </p>
                            </div>
                            <div className="work2-right-download-wrapper">
                                <a className="work2-right-download work2-right-download-green" target="__blank" href="https://pp-start.pl/demo/webcam">Uruchom demo</a>
                            </div>
                        </div>
                    </div>
                    <div className="second-separator-wrapper separator-outer-wrapper">
                        <div className="divider-line"></div>
                        <div className="second-divider-symbol-wrapper divider-symbol-wrapper">
                            <span className="divider-symbol">&#10070;</span>
                        </div>
                    </div>
                    <div className="work2-content-wrapper">
                        <div className="work2-left-wrapper">
                            <img className="work2-left-image" src={ApplicationComposition2} alt="application graphic"/>
                        </div>
                        <div className="work2-right-wrapper">
                            <div className="work2-right-heading-wrapper">
                                <h3 className="work2-right-heading">Badania Ruchu</h3>
                            </div>
                            <div className="work2-right-description-wrapper">
                                <p className="work2-right-description">Mobilna aplikacja przeznaczona dla pracowników prowadzących pomiary natężenia ruchu wewnątrz pociągów. Umożliwia przejrzystą organizację pracy w terenie, dostęp do harmonogramu, rozkładów jazdy i wygodne wprowadzanie pomiarów.</p>
                            </div>
                            <div className="work2-right-download-wrapper">
                                <a className="work2-right-download work2-right-download-green" target="__blank" href="https://pp-start.pl/demo/traffic">Uruchom demo</a>
                            </div>
                        </div>
                    </div>
                    <div className="second-separator-wrapper separator-outer-wrapper">
                        <div className="divider-line"></div>
                        <div className="second-divider-symbol-wrapper divider-symbol-wrapper">
                            <span className="divider-symbol">&#10070;</span>
                        </div>
                    </div>
                    <div className="work2-content-wrapper">
                        <div className="work2-left-wrapper">
                            <img className="work2-left-image" src={ApplicationComposition3} alt="application graphic"/>
                        </div>
                        <div className="work2-right-wrapper">
                            <div className="work2-right-heading-wrapper">
                                <h3 className="work2-right-heading">Rubika Datapad</h3>
                            </div>
                            <div className="work2-right-description-wrapper">
                                <p className="work2-right-description">Aplikacja webowa przeznaczona na komputery i laptopy. Zapewnia sprawną organizację i wygodną pracę przy badaniu natężenia ruchu pasażerów na stacjach kolejowych.</p>
                            </div>
                            <div className="work2-right-download-wrapper">
                                <a className="work2-right-download work2-right-download-green" target="__blank" href="https://pp-start.pl/demo/datapad">Uruchom demo</a>
                            </div>
                        </div>
                    </div>
                    <div className="second-separator-wrapper separator-outer-wrapper">
                        <div className="divider-line"></div>
                        <div className="second-divider-symbol-wrapper divider-symbol-wrapper">
                            <span className="divider-symbol">&#10070;</span>
                        </div>
                    </div>
                    <div className="work2-heading-wrapper">
                        <h2 className="work2-heading">Strony internetowe</h2>
                    </div>
                    <div className="work2-content-wrapper">
                        <div className="work2-left-wrapper">
                            <img className="work2-left-image" src={WebsiteComposition1} alt="website composition 1"/>
                        </div>
                        <div className="work2-right-wrapper">
                            <div className="work2-right-heading-wrapper">
                                <h3 className="work2-right-heading">Łukasz Sroczyński</h3>
                            </div>
                            <div className="work2-right-logo-wrapper">
                                <a href="https://lukaszsroczynski.pl" target="_blank" rel="noreferrer"><img className="work2-right-logo" src={CustomerLogo1} alt="website logo 1"/></a>
                            </div>
                            <div className="work2-right-description-wrapper">
                                <p className="work2-right-description">Strona i blog specjalisty ds. finansów zbudowane na najpopularniejszym systemie zarządzania treścią - Wordpress. Ciekawą opcją jest możliwość sprawdzenia swojej zdolności kredytowej.</p>
                            </div>
                        </div>
                    </div>
                    <div className="second-separator-wrapper separator-outer-wrapper">
                        <div className="divider-line"></div>
                        <div className="second-divider-symbol-wrapper divider-symbol-wrapper">
                            <span className="divider-symbol">&#10070;</span>
                        </div>
                    </div>
                    <div className="work2-content-wrapper">
                        <div className="work2-left-wrapper">
                            <img className="work2-left-image" src={WebsiteComposition2} alt="website composition 2"/>
                        </div>
                        <div className="work2-right-wrapper">
                            <div className="work2-right-heading-wrapper">
                                <h3 className="work2-right-heading">Kris Pracz</h3>
                            </div>
                            <div className="work2-right-logo-wrapper">
                                <a href="https://krispracz.pl" target="_blank" rel="noreferrer"><img className="work2-right-logo" src={CustomerLogo2} alt="website logo 2"/></a>
                            </div>
                            <div className="work2-right-description-wrapper">
                                <p className="work2-right-description">Strona firmy zajmującej się m.in. praniem tapicerki meblowej, dywanów i wykładzin. Polecam galerię prezentującą efekty ich prac w porównaniu: przed i po wykonaniu czyszczenia.</p>
                            </div>
                        </div>
                    </div>
                    <div className="second-separator-wrapper separator-outer-wrapper">
                        <div className="divider-line"></div>
                        <div className="second-divider-symbol-wrapper divider-symbol-wrapper">
                            <span className="divider-symbol">&#10070;</span>
                        </div>
                    </div>
                    <div className="work2-content-wrapper">
                        <div className="work2-left-wrapper">
                            <img className="work2-left-image" src={WebsiteComposition3} alt="website composition 3"/>
                        </div>
                        <div className="work2-right-wrapper">
                            <div className="work2-right-heading-wrapper">
                                <h3 className="work2-right-heading">Scheppler Truck Service</h3>
                            </div>
                            <div className="work2-right-logo-wrapper">
                                <a href="https://tirserwis.gniezno.pl" target="_blank" rel="noreferrer"><img className="work2-right-logo" src={CustomerLogo3} alt="website logo 3"/></a>
                            </div>
                            <div className="work2-right-description-wrapper">
                                <p className="work2-right-description">Strona internetowa serwisu pojazdów ciężarowych oferujących usługi mechaniki oraz pomocy mobilnej. Szczególnie interesująca jest galeria ukazująca wiele akcji pomocy powypadkowej w terenie.</p>
                            </div>
                        </div>
                    </div>
                    <div className="second-separator-wrapper separator-outer-wrapper">
                        <div className="divider-line"></div>
                        <div className="second-divider-symbol-wrapper divider-symbol-wrapper">
                            <span className="divider-symbol">&#10070;</span>
                        </div>
                    </div>
                    <div className="work2-content-wrapper">
                        <div className="work2-left-wrapper">
                            <img className="work2-left-image" src={WebsiteComposition4} alt="website composition 4"/>
                        </div>
                        <div className="work2-right-wrapper">
                            <div className="work2-right-heading-wrapper">
                                <h3 className="work2-right-heading">Bezpieczny Kredyt 2%</h3>
                            </div>
                            <div className="work2-right-logo-wrapper">
                                <a href="https://bezpieczny-kredyt-poznan.pl" target="_blank" rel="noreferrer"><img className="work2-right-logo" src={CustomerLogo4} alt="website logo 4"/></a>
                            </div>
                            <div className="work2-right-description-wrapper">
                                <p className="work2-right-description">Strona internetowa nowego programu rządowego Bezpieczny Kredyt. Na uwagę zasługuje wykonany przeze mnie kalkulator, który na bieżąco dla zadanych parametrów kredytu przelicza oszczędności na odsetkach.</p>
                            </div>
                        </div>
                    </div>
                    <div className="work2-heading-wrapper">
                        <h2 className="work2-heading">Grafika wektorowa</h2>
                    </div>
                    <div className="work2-content-wrapper">
                        <div className="work2-left-wrapper">
                            <img className="work2-left-image" src={GraphicsComposition1} alt="graphics composition 1"/>
                        </div>
                        <div className="work2-right-wrapper">
                            <div className="work2-right-heading-wrapper">
                                <h3 className="work2-right-heading">Darfol Opakowania</h3>
                            </div>
                            <div className="work2-right-logo-wrapper">
                                <a href="http://opakowania.gniezno.pl/" target="_blank" rel="noreferrer"><img className="work2-right-logo" src={CustomerLogo5} alt="website logo 4"/></a>
                            </div>
                            <div className="work2-right-description-wrapper">
                                <p className="work2-right-description">Darfol to firma specjalizująca się w produkcji opakowań foliowych i środków czystości. Dla klienta wykonałem wiele etykiet potrzebnych do nowo wprowadzanych produktów.</p>
                            </div>
                            <div className="work2-right-download-wrapper">
                                <a className="work2-right-download" href="https://pp-start.pl/download/darfol.zip" download>Pobierz prace</a>
                            </div>
                        </div>
                    </div>
                    <div className="second-separator-wrapper separator-outer-wrapper">
                        <div className="divider-line"></div>
                        <div className="second-divider-symbol-wrapper divider-symbol-wrapper">
                            <span className="divider-symbol">&#10070;</span>
                        </div>
                    </div>
                    <div className="work2-content-wrapper">
                        <div className="work2-left-wrapper">
                            <img className="work2-left-image" src={GraphicsComposition2} alt="graphics composition 2"/>
                        </div>
                        <div className="work2-right-wrapper">
                            <div className="work2-right-heading-wrapper">
                                <h3 className="work2-right-heading">Fiberoptik</h3>
                            </div>
                            <div className="work2-right-logo-wrapper">
                                <a href="https://fiberoptik.com.pl/" target="_blank" rel="noreferrer"><img className="work2-right-logo" src={CustomerLogo6} alt="website logo 5"/></a>
                            </div>
                            <div className="work2-right-description-wrapper">
                                <p className="work2-right-description">Fiberoptik to firma specjalizująca się w budowie kanalizacji, a także tras światłowodowych. Dla klienta wykonałem folder reklamowy oraz firmowy kalendarz.</p>
                            </div>
                            <div className="work2-right-download-wrapper">
                                <a className="work2-right-download" href="https://pp-start.pl/download/fiberoptik.zip" download>Pobierz prace</a>
                            </div>
                        </div>
                    </div>
                    <div className="work2-heading-wrapper">
                        <h2 className="work2-heading">Grafika rastrowa</h2>
                    </div>
                    <div className="work2-content-wrapper">
                        <div className="work2-left-wrapper">
                            <img className="work2-left-image" src={GraphicsComposition3} alt="graphics composition 3"/>
                        </div>
                        <div className="work2-right-wrapper">
                            <div className="work2-right-heading-wrapper">
                                <h3 className="work2-right-heading">Biene Maja</h3>
                            </div>
                            <div className="work2-right-logo-wrapper">
                                <img className="work2-right-logo" src={CustomerLogo7} alt="website logo 6"/>
                            </div>
                            <div className="work2-right-description-wrapper">
                                <p className="work2-right-description">Biene Maja to firma zajmująca się sprzedażą produktów spożywczych i chemii gospodarczej głównie z rynku niemieckiego. Dla klienta wykonałem logo oraz wiele banerów reklamowych.</p>
                            </div>
                            <div className="work2-right-download-wrapper">
                                <a className="work2-right-download" href="https://pp-start.pl/download/bienemaja.zip" download>Pobierz prace</a>
                            </div>
                        </div>
                    </div>
                    <div className="second-separator-wrapper separator-outer-wrapper">
                        <div className="divider-line"></div>
                        <div className="second-divider-symbol-wrapper divider-symbol-wrapper">
                            <span className="divider-symbol">&#10070;</span>
                        </div>
                    </div>
                    <div className="work2-content-wrapper">
                        <div className="work2-left-wrapper">
                            <img className="work2-left-image" src={GraphicsComposition4} alt="graphics composition 4"/>
                        </div>
                        <div className="work2-right-wrapper">
                            <div className="work2-right-heading-wrapper">
                                <h3 className="work2-right-heading">Energia Świetlna</h3>
                            </div>
                            <div className="work2-right-logo-wrapper">
                                <a href="https://energiaswietlna.pl/" target="_blank" rel="noreferrer"><img className="work2-right-logo" src={CustomerLogo8} alt="website logo 7"/></a>
                            </div>
                            <div className="work2-right-description-wrapper">
                                <p className="work2-right-description">Energia świetlna to firma zajmująca się kompleksowym wykonaniem instalacji fotowoltaicznych i montażem pomp ciepła. Dla klienta wykonałem banery reklamowe.</p>
                            </div>
                            <div className="work2-right-download-wrapper">
                                <a className="work2-right-download" href="https://pp-start.pl/download/energia.zip" download>Pobierz prace</a>
                            </div>
                        </div>
                    </div>
                    <div className="second-separator-wrapper separator-outer-wrapper">
                        <div className="divider-line"></div>
                        <div className="second-divider-symbol-wrapper divider-symbol-wrapper">
                            <span className="divider-symbol">&#10070;</span>
                        </div>
                    </div>
                    <div className="work2-content-wrapper">
                        <div className="work2-left-wrapper">
                            <img className="work2-left-image" src={GraphicsComposition5} alt="graphics composition 5"/>
                        </div>
                        <div className="work2-right-wrapper">
                            <div className="work2-right-heading-wrapper">
                                <h3 className="work2-right-heading">Łodzie Online</h3>
                            </div>
                            <div className="work2-right-logo-wrapper">
                                <a href="https://lodzie.online/" target="_blank" rel="noreferrer"><img className="work2-right-logo" src={CustomerLogo9} alt="website logo 8"/></a>
                            </div>
                            <div className="work2-right-description-wrapper">
                                <p className="work2-right-description">Łodzie Online to serwis z ogłoszeniami kupna i sprzedaży łodzi, części mechanicznych oraz akcesoriów marynistycznych. Dla klienta wykonałem logo i baner reklamowy.</p>
                            </div>
                            <div className="work2-right-download-wrapper">
                                <a className="work2-right-download" href="https://pp-start.pl/download/lodzie.zip" download>Pobierz prace</a>
                            </div>
                        </div>
                    </div>
                    <div className="second-separator-wrapper separator-outer-wrapper">
                        <div className="divider-line"></div>
                        <div className="second-divider-symbol-wrapper divider-symbol-wrapper">
                            <span className="divider-symbol">&#10070;</span>
                        </div>
                    </div>
                    <div className="work2-content-wrapper">
                        <div className="work2-left-wrapper">
                            <img className="work2-left-image" src={GraphicsComposition6} alt="graphics composition 6"/>
                        </div>
                        <div className="work2-right-wrapper">
                            <div className="work2-right-heading-wrapper">
                                <h3 className="work2-right-heading">Rumcajs Shop</h3>
                            </div>
                            <div className="work2-right-logo-wrapper">
                                <img className="work2-right-logo" src={CustomerLogo10} alt="website logo 9"/>
                            </div>
                            <div className="work2-right-description-wrapper">
                                <p className="work2-right-description">Rumcajs Shop to stacjonarny sklep oferujący napoje alkoholowe i słodycze. Dla klienta wykonałem logo oraz kilka animacji przeznaczonych na stacjonarny wyświetlacz.</p>
                            </div>
                            <div className="work2-right-download-wrapper">
                                <a className="work2-right-download" href="https://pp-start.pl/download/rumcajs.zip" download>Pobierz prace</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {<div id="work3-outer-container">
                <img id="work3-image" src={IntroImage3} alt="background"/>
                <p id="work3-text-left" className={isInView.left ? "left-in-view work3-text" : "work3-text"} name="left">Masz pomysł?</p>
                <p id="work3-text-right" className={isInView.right ? "right-in-view work3-text" : "work3-text"} name="right">Zrealizujmy go!</p>
            </div>}

        </div>
    );
};