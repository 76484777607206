import React, { useState, useEffect } from "react";

import ContactImage1 from "../images/contact1.webp";
import ContactImage2 from "../images/contact2.webp";
import ContactImage3 from "../images/contact3.webp";

import {ReactComponent as Icon1} from '../images/svg/contact_icon_1.svg';
import {ReactComponent as Icon2} from '../images/svg/contact_icon_2.svg';
import {ReactComponent as Icon3} from '../images/svg/contact_icon_3.svg';
import {ReactComponent as Icon4} from '../images/svg/contact_icon_4.svg';

import Letter from "./Letter";

export default function Contact(){

    const [offsetY, setOffsetY] = useState(0);

    const handleScroll = () => {

        setOffsetY(window.scrollY);

    };
    
    useEffect(() => {

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);

    }, []);

    function scrollToDiv(id){

        let element = document.getElementById(id);

        if(element){

            const elementPosition = element.getBoundingClientRect().top + window.scrollY;

            window.scrollTo({

                top: elementPosition - 70,

                behavior: 'smooth'

            });

        }

    }

    return (
        <>
            <div className="page">
                <div id="contact1-outer-container">
                    <div id="contact1-outer-wrapper">
                        <div id="contact1-image-wrapper" style={{ backgroundPositionY: `${offsetY * 0.3}px` }}>
                            <img id="contact1-image" src={ContactImage1} alt="contact me"/>
                        </div>
                    </div>
                </div>
                <div id="contact2-outer-container">
                    <div id="contact2-outer-wrapper">
                        <div id="contact2-heading-wrapper">
                            <h1 id="contact2-heading">Skontaktuj się ze mną i zacznijmy tworzyć</h1>
                        </div>
                    </div>
                </div>
                <div id="contact3-outer-container">
                    <div id="contact3-outer-wrapper">
                        <Letter />
                    </div>
                </div>
                <div className="second-separator-wrapper separator-outer-wrapper">
                    <div className="divider-line"></div>
                    <div className="second-divider-symbol-wrapper divider-symbol-wrapper">
                        <span className="divider-symbol">&#10070;</span>
                    </div>
                </div>
                <div id="contact4-outer-container">
                    <div id="contact4-outer-wrapper">
                        <div id="contact4-left-wrapper">
                            <img id="contact4-image" src={ContactImage2} alt="call me"/>
                        </div>
                        <div id="contact4-right-wrapper">
                            <h3 id="contact4-heading">Zadzwoń do mnie</h3>
                            <p id="contact4-description">Jestem do Twojej dyspozycji codziennie</p>
                            <h3 id="contact4-phone"><a id="contact4-phone-link" href="tel:662890561">662 890 561</a></h3>
                        </div>
                    </div>
                </div>
                <div className="second-separator-wrapper separator-outer-wrapper">
                    <div className="divider-line"></div>
                    <div className="second-divider-symbol-wrapper divider-symbol-wrapper">
                        <span className="divider-symbol">&#10070;</span>
                    </div>
                </div>
                <div id="contact5-outer-container">
                    <div id="contact5-outer-wrapper">
                        <div id="contact5-left-wrapper">
                            <div id="map-wrapper">
                                <div id="google-map">
                                    <iframe id="google-frame" src="https://maps.google.com/maps?q=PP%20Start&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" title="map"></iframe>
                                </div>
                            </div>
                        </div>
                        <div id="contact5-right-wrapper">
                            <h3 id="contact5-heading">Odwiedź mnie</h3>
                            <p id="contact5-description">Możesz mnie zastać pod adresem:</p>
                            <p className="contact5-address">Żwirki i Wigury 13</p>
                            <p className="contact5-address">62-200 Gniezno</p>
                        </div>
                    </div>
                </div>
                <div className="second-separator-wrapper separator-outer-wrapper">
                    <div className="divider-line"></div>
                    <div className="second-divider-symbol-wrapper divider-symbol-wrapper">
                        <span className="divider-symbol">&#10070;</span>
                    </div>
                </div>
                <div id="contact6-outer-container">
                    <div id="contact6-outer-wrapper">
                        <h3 id="contact6-heading">Wybierz preferowaną formę kontaktu</h3>
                        <div id="contact6-icons-wrapper">
                            <Icon1 className="contact-icon-image" onClick={() => scrollToDiv('contact3-outer-container')}/>
                            <Icon2 className="contact-icon-image" onClick={() => scrollToDiv('contact4-outer-wrapper')}/>
                            <Icon3 className="contact-icon-image" onClick={() => scrollToDiv('contact4-outer-wrapper')}/>
                            <Icon4 className="contact-icon-image" onClick={() => scrollToDiv('contact5-outer-wrapper')}/>
                        </div>
                        <img id="contact6-image" src={ContactImage3} alt="contact methods"/>
                    </div>
                </div>
            </div>
        </>
    );
};