import React, { useEffect, useState } from 'react';

import CookieImage from '../images/cookie.webp';

export default function Cookies(){

    const [show, setShow] = useState(false);

    useEffect(() => {

        const consentGiven = document.cookie.split('; ').find(row => row.startsWith('cookieConsent='));

        if(!consentGiven){

            setShow(true);

        }

    }, []);

    function acceptCookies(){

        document.cookie = "cookieConsent=true; path=/; max-age=" + 60 * 60 * 24 * 90;

        setShow(false);

    }

    return (
        <>
            <div id="cookies-outer-container" className={show ? "cookies-show" : ""}>
                <div id="cookies-outer-wrapper">
                    <span id="cookies-close" onClick={() => setShow(false)}>&#10006;</span>
                    <div id="cookies-inner-container">
                        <p id="cookies-title">Czy ktoś powiedział "ciasteczka"?</p>
                        <div id="cookies-inner-wrapper">
                            <img id="cookies-image" src={CookieImage} alt="cookies"/>
                            <p id="cookies-text">Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie.</p>
                            <button id="cookies-consent" onClick={() => acceptCookies()}>Zgoda</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}