import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import ReCAPTCHA from 'react-google-recaptcha';

import {ReactComponent as SuccessSVG} from '../images/svg/success.svg';

import SentStamp from "../images/sent-stamp.webp";

export default function Letter(){

    const recaptchaRef = useRef(null);

    const siteKey = "6Lel6VYiAAAAAILcvQXY1PBS8_iP-K6VjzD6K8ll";

    const Axios = axios.create({
        baseURL: 'php/',
    });

    // Active - animacja przycisku

    // Start - ukrywanie stopki i przycisku, wkładanie do koperty

    // Envelope - zamykanie koperty

    // Completed - pokazywanie pieczątki

    const [letterAnimation, setLetterAnimation] = useState({active: false, completed: false, start: false, envelope: false});

    //function sendMessage(e){

    const [formErrors, setFormErrors] = useState([]);

    const sendMessage = async (e) => {

        let button = document.getElementById('letter-submit');

        button.setAttribute("disabled", "disabled");

        e.preventDefault();

        const errors = [];

        let mailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        let sender = contactForm.sender;

        let email = contactForm.email;

        let message = contactForm.message;

        if(sender === ""){

            errors.push('uzupełnij pole "nadawca"');

        }

        if(email === ""){

            errors.push('podaj swój adres email');

        }

        if(email !== '' && !email.match(mailRegex)){

            errors.push('wpisz poprawny adres email');

        }

        if(message === ""){

            errors.push('uzupełnij pole "wiadomość"');

        }

        if(errors.length > 0){

            button.removeAttribute("disabled");

            setFormErrors(errors);

            setModal(true);

            return;

        }

        const token = await recaptchaRef.current.executeAsync();

        recaptchaRef.current.reset();

        Axios.post('letter.php', { sender, email, message, token }, { timeout: 10000 }).then(function(response){
            if(response.data.success){

                setLetterAnimation({...letterAnimation, active: true});

            } else if(response.data.message){

                button.removeAttribute("disabled");

                setErrorMessage(response.data.message);

                setModal(true);
                
            }
            
        }).catch((error) => {

            button.removeAttribute("disabled");

            setErrorMessage('Wystąpił błąd w trakcie wysyłania listu. Spróbuj ponownie, a jeśli problem będzie się powtarzał skontaktuj się telefonicznie.');

            setModal(true);

            console.warn(error);
            
        });

    }

    useEffect(() => {

        if(letterAnimation.active){

            setTimeout(() => {

                setLetterAnimation(prevData => {

                    return {
                        ...prevData,
                        start: true
                    }

                });  

            }, 1000); 
            
        }

    }, [letterAnimation.active]);

    useEffect(() => {

        if(letterAnimation.start){

            setTimeout(() => {

                setLetterAnimation(prevData => {

                    return {
                        ...prevData,
                        envelope: true
                    }

                });  

            }, 4000); 
            
        }

    }, [letterAnimation.start]);

    const [contactForm, setContactForm] = useState({sender: "", email: "", message: ""});

    const [errorMessage, setErrorMessage] = useState(null);

    function onContactFormChange(e){

        const value = e.target.value;

        const name = e.target.name;

        setContactForm(prev => {
            return {
                ...prev,
                [name]: value
            }
        });

    }

    useEffect(() => {

        window.addEventListener('resize', centerLetter);

        centerLetter();

        return () => window.removeEventListener('resize', centerLetter);

    }, []);

    const [styles, setStyles] = useState({padding: null, left: null})

    function centerLetter(){

        const width = window.innerWidth;

        if(width >= 1100){

            setStyles({padding: "0", left: "600px"});

        } else if(width >= 950){

            let difference = (width - 900)/3;

            const padding = difference + "px";

            const left = (500 + difference -16) + "px";

            setStyles({padding: padding, left: left});

        } else if(width >= 520){

            const padding = "0";

            const left = "50px";

            setStyles({padding: padding, left: left});

        } else {

            const padding = "0";

            const left = "25px";

            setStyles({padding: padding, left: left});

        }

    }

    const [modal, setModal] = useState(false);

    function closeModal(){

        setFormErrors([]);

        setErrorMessage(null);

        setModal(false);

    }

    return (
        <div id="main5-bottom-wrapper" className={letterAnimation.envelope ? "closed-envelope" : ""}>
            <div id="envelope-wrapper" style={{paddingLeft: styles.padding, paddingRight: styles.padding}}>
                <div id="envelope">
                    <div id="envelope-back" className={letterAnimation.envelope ? "envelope-transit" : ""} onTransitionEnd={() => setLetterAnimation({...letterAnimation, completed: true})}></div>
                    <form id="letter" className={letterAnimation.start ? "letter-transit" : ""} style={{left: styles.left}}>
                        <p>
                            <label className="letter-label">Nadawca:</label>
                            <input 
                                className="letter-input" 
                                type="text" 
                                name="sender" 
                                value={contactForm.sender}
                                onChange={(e) => onContactFormChange(e)}
                            />
                        </p>
                        <p>
                            <label className="letter-label">Email:</label>
                            <input 
                                className="letter-input" 
                                type="email" 
                                name="email"
                                value={contactForm.email}
                                onChange={(e) => onContactFormChange(e)}
                            />
                        </p>
                        <p>
                            <label className="letter-label">Wiadomość:</label>
                            <textarea 
                                className="letter-input"
                                type="text"
                                id="letter-textarea"
                                name="message"
                                value={contactForm.message}
                                onChange={(e) => onContactFormChange(e)}
                            />
                        </p>
                        <ReCAPTCHA
                            sitekey={siteKey}
                            size="invisible"
                            ref={recaptchaRef}
                        />
                        <div className={letterAnimation.start ? "letter-footer letter-footer-fade-out": "letter-footer"} onAnimationEnd={() => setLetterAnimation({start: true, envelope: false, footer: true})}>
                            <p id="letter-info">Administratorem danych osobowych jest PP Start. Dane wpisane w formularzu będą przetwarzane w celu udzielenia odpowiedzi na przesłane zapytanie zgodnie z obecną <a className="quick-chat-link" href="https://pp-start.pl/polityka-prywatnosci">polityką prywatności</a>.</p>
                            <div className="letter-submit-wrapper">
                                <button id="letter-submit" onClick={(e) => sendMessage(e)} className={letterAnimation.active ? "is_active" : ""}>
                                    <span>Wyślij</span>
                                    <div className={letterAnimation.active ? "success-visible success" : "success"}>
                                        <SuccessSVG/>
                                    </div>
                                </button>
                            </div>
                            <p id="letter-error"></p>
                        </div>
                    </form>
                    <div id="letter-success" className={letterAnimation.completed ? "letter-success-visible" : ""}>
                        <img id="letter-sent-stamp" src={SentStamp} alt="sent-stamp"/>
                    </div>
                </div>
                <div className={letterAnimation.completed ? "letter-thank-you-container letter-thank-you-visible" : "letter-thank-you-container"}>
                    <p className="letter-success-text">Dziękuję za wiadomość!</p>
                    <p className="letter-success-text">&#128578;</p>
                    <p className="letter-success-text">Odpowiem w ciągu 24 godzin.</p>
                </div>
            </div>
            {modal && 
                <div className="modal-overlay" onClick={() => closeModal()}>
                    <div className="modal" onClick={(e)=>e.stopPropagation()}>
                        <div className="modal-header">
                            <h2 className="modal-title">Błąd</h2>
                        </div>
                        <div className="modal-body">
                            {formErrors.length > 0 && 
                                <div className="modal-info-wrapper">
                                    <p className="modal-info-text">Przed wysłaniem listu:</p>
                                    <ul className="modal-error-ul">
                                        {formErrors.map((error, index) => (
                                            <li className="modal-error-list" key={index}>{error}</li>
                                        ))}
                                    </ul>
                                </div>
                            }
                            {errorMessage && 
                                <div className="modal-info-wrapper">
                                    <p className="modal-info-text">{errorMessage}</p>
                                </div>
                            }
                        </div>
                        <div className="modal-footer">
                            <div className="modal-buttons-wrapper"> 
                                <button className="modal-button" onClick={() => closeModal()}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};