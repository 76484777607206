import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './styles/style.scss';

export { default as Common } from './components/Common';
export { default as Main } from './components/Main';
export { default as About } from './components/About';
export { default as Services } from './components/Services';
export { default as Contact } from './components/Contact';
export { default as Portfolio } from './components/Portfolio';
export { default as Privacy } from './components/Privacy';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);