import React, { useEffect, useRef, useState } from 'react';

const ProgressBar = ({ percentage }) => {

    const [isVisible, setIsVisible] = useState(false);

    const progressContentRef = useRef(null);

    const progressNumberMarkRef = useRef(null);

    const progressBarRef = useRef(null);

    useEffect(() => {

        const progressBar = progressBarRef.current;

        const observer = new IntersectionObserver((entries) => {

            entries.forEach(entry => {

                if(entry.isIntersecting){

                    setIsVisible(true);

                }

            });

        });

        observer.observe(progressBar);

        return () => {
            observer.unobserve(progressBar);
        };

    }, []);

    useEffect(() => {

        if(isVisible){

            const progressContent = progressContentRef.current;

            const progressNumberMark = progressNumberMarkRef.current;

            const animateProgress = (start, end, duration, element, styleProp) => {

                const increment = (end - start) / duration * 10;

                let current = start;

                const step = () => {

                    current += increment;

                    if(current >= end){

                        current = end;

                    }

                    element.style[styleProp] = `${current-1}%`;

                    if(current < end){

                        requestAnimationFrame(step);

                    }

                };

                requestAnimationFrame(step);

            };

            const animateNumber = (start, end, duration, element) => {

                const increment = (end - start) / duration * 10;

                let current = start;

                const step = () => {

                    current += increment;

                    if(current >= end){

                        current = end;

                    }

                    element.style.left = `${current}%`;

                    element.querySelector('.percent').textContent = `${Math.round(current)}%`;

                    if(current < end){

                        requestAnimationFrame(step);
                    }

                };

                requestAnimationFrame(step);

            };

            animateProgress(0, percentage, 2000, progressContent, 'width');

            animateNumber(0, percentage, 2000, progressNumberMark);

        }

    }, [isVisible, percentage]);

    return (
        <div className="progress-bar" ref={progressBarRef} data-percentage={percentage}>
            <div className="progress-content" ref={progressContentRef}></div>
            <div className="progress-number-mark" ref={progressNumberMarkRef}>
                <span className="percent">0%</span>
            </div>
        </div>
    );
};

export default ProgressBar;