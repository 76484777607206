import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Cookies from "./Cookies";

export default function Common() {
    return (
    <>
        <div id="main-container">
            <Header />
            <Outlet />
            <Footer />
            <Cookies />
        </div>
    </>
    )
}