import React, { useState, useEffect } from "react";
import { Accordion } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

import ServicesIcon1 from '../images/services2.webp';
import ServicesIcon2 from '../images/services3.webp';
import ServicesIcon3 from '../images/services4.webp';
import ServicesIcon4 from '../images/services5.webp';
import ServicesIcon5 from '../images/services6.webp';
import ServicesIcon6 from '../images/services7.webp';

import QuestionMark from '../images/services8.webp';

export default function Services(){

    const [offsetY, setOffsetY] = useState(0);

    const handleScroll = () => {

        setOffsetY(window.scrollY);

    };
    
    useEffect(() => {

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);

    }, []);

    const [inViewElements, setInViewElements] = useState([]);

    useEffect(() => {

        const elements = document.querySelectorAll(".services2-outer-wrapper");

        const observer = new IntersectionObserver(

            (entries) => {

                entries.forEach((entry) => {

                    if(entry.isIntersecting){

                        setInViewElements((prev) => [...prev, entry.target]);

                    }

                });

            },

            { threshold: 0.5 } // Trigger when 10% of the div is in view

        );

        elements.forEach((element) => observer.observe(element));

        return () => {

            elements.forEach((element) => observer.unobserve(element));

        };

    }, []);

    /*

    <div className='placeholder-services2-text-wrapper'>
                            <div className={`services2-text-wrapper-absolute moved-right ${inViewElements.includes(document.querySelectorAll(".services2-outer-wrapper")[0]) ? "slide-in-right" : "" }`}>
                                <p className="services2-text">Zaprojektuję i wykonam dla Ciebie nowoczesną i funkcjonalną stronę lub sklep, które z miejsca staną się Twoją wizytówką w sieci.</p>
                            </div>
                        </div>

    <div className={`services2-text-wrapper moved-right ${inViewElements.includes(document.querySelectorAll(".services2-outer-wrapper")[0]) ? "slide-in-right" : "" }`}>
                                <p className="services2-text">Zaprojektuję i wykonam dla Ciebie nowoczesną i funkcjonalną stronę lub sklep, które z miejsca staną się Twoją wizytówką w sieci.</p>
                            </div>

    */

    return (
        <>
            <div className="page">
                <div id="services1-outer-container">
                    <div id="services1-outer-wrapper" style={{ backgroundPositionY: `${offsetY * 0.3}px` }}>
                        <div id="services1-inner-wrapper">
                            <h1 id="services1-heading">Z przyjemnością wykonam dla Ciebie usługi:</h1>
                            <ul id="services1-list">
                                <li>stworzenia strony lub sklepu internetowego</li>
                                <li>wykonania aplikacji webowej lub na android</li>
                                <li>doboru i ustawienia hostingu oraz domeny</li>
                                <li>wykonania projektów do druku(np. ulotki)</li>
                                <li>utworzenia grafik do internetu(np. logo)</li>
                                <li>pomocy z zakresu informatyki</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="services2-outer-container">
                    <div className="services2-outer-wrapper">
                        <div className={`services2-photo-wrapper moved-left ${inViewElements.includes(document.querySelectorAll(".services2-outer-wrapper")[0]) ? "slide-in-left" : ""}`}>
                            <img className="services2-photo" src={ServicesIcon1} alt="web-design"/>
                        </div>
                        <div className={`services2-text-wrapper moved-right ${inViewElements.includes(document.querySelectorAll(".services2-outer-wrapper")[1]) ? "slide-in-right" : "" }`}>
                            <p className="services2-text">Zaprojektuję i wykonam dla Ciebie nowoczesną i funkcjonalną stronę lub sklep, które z miejsca staną się Twoją wizytówką w sieci.</p>
                        </div>
                    </div>
                    <div className="separator-outer-wrapper">
                        <div className="divider-line"></div>
                        <div className="divider-symbol-wrapper">
                            <span className="divider-symbol">&#10070;</span>
                        </div>
                    </div>
                    <div className="services2-outer-wrapper">
                        <div className={`services2-photo-wrapper moved-left ${inViewElements.includes(document.querySelectorAll(".services2-outer-wrapper")[1]) ? "slide-in-left" : ""}`}>
                            <img className="services2-photo" src={ServicesIcon2} alt="app-design"/>
                        </div>
                        <div className={`services2-text-wrapper moved-right ${inViewElements.includes(document.querySelectorAll(".services2-outer-wrapper")[1]) ? "slide-in-right" : "" }`}>
                            <p className="services2-text">Wykonam dla Ciebie funkcjonalną aplikację dostępną zarówno w tradycyjnej przeglądarce internetowej oraz w formie apki ze sklepu play na telefony z systemem android.</p>
                        </div>
                    </div>
                    <div className="separator-outer-wrapper">
                        <div className="divider-line"></div>
                        <div className="divider-symbol-wrapper">
                            <span className="divider-symbol">&#10070;</span>
                        </div>
                    </div>
                    <div className="services2-outer-wrapper">
                        <div className={`services2-photo-wrapper moved-left ${inViewElements.includes(document.querySelectorAll(".services2-outer-wrapper")[2]) ? "slide-in-left" : ""}`}>
                            <img className="services2-photo" src={ServicesIcon3} alt="hosting"/>
                        </div>
                        <div className={`services2-text-wrapper moved-right ${inViewElements.includes(document.querySelectorAll(".services2-outer-wrapper")[2]) ? "slide-in-right" : "" }`}>
                            <p className="services2-text">Dobiorę dla Ciebie optymalną ofertę hostingu oraz domeny. Zajmę się wszystkimi formalnościami i uruchomieniem od strony technicznej.</p>
                        </div>
                    </div>
                    <div className="separator-outer-wrapper">
                        <div className="divider-line"></div>
                        <div className="divider-symbol-wrapper">
                            <span className="divider-symbol">&#10070;</span>
                        </div>
                    </div>
                    <div className="services2-outer-wrapper">
                        <div className={`services2-photo-wrapper moved-left ${inViewElements.includes(document.querySelectorAll(".services2-outer-wrapper")[3]) ? "slide-in-left" : ""}`}>
                            <img className="services2-photo" src={ServicesIcon4} alt="vector graphics"/>
                        </div>
                        <div className={`services2-text-wrapper moved-right ${inViewElements.includes(document.querySelectorAll(".services2-outer-wrapper")[3]) ? "slide-in-right" : "" }`}>
                            <p className="services2-text">Zaprojektuję i przygotuję materiały do druku - zarówno wielkoformatowe banery jak również ulotki, broszury, etykiety czy też wizytówki.</p>
                        </div>
                    </div>
                    <div className="separator-outer-wrapper">
                        <div className="divider-line"></div>
                        <div className="divider-symbol-wrapper">
                            <span className="divider-symbol">&#10070;</span>
                        </div>
                    </div>
                    <div className="services2-outer-wrapper">
                        <div className={`services2-photo-wrapper moved-left ${inViewElements.includes(document.querySelectorAll(".services2-outer-wrapper")[4]) ? "slide-in-left" : ""}`}>
                            <img className="services2-photo" src={ServicesIcon5} alt="web graphics"/>
                        </div>
                        <div className={`services2-text-wrapper moved-right ${inViewElements.includes(document.querySelectorAll(".services2-outer-wrapper")[4]) ? "slide-in-right" : "" }`}>
                            <p className="services2-text">Wykonam wszelkiego rodzaju pliki graficzne do użycia w internecie takie jak logo na stronę, baner reklamowy, czy też animowanego gifa.</p>
                        </div>
                    </div>
                    <div className="separator-outer-wrapper">
                        <div className="divider-line"></div>
                        <div className="divider-symbol-wrapper">
                            <span className="divider-symbol">&#10070;</span>
                        </div>
                    </div>
                    <div className="services2-outer-wrapper">
                        <div className={`services2-photo-wrapper moved-left ${inViewElements.includes(document.querySelectorAll(".services2-outer-wrapper")[5]) ? "slide-in-left" : ""}`}>
                            <img className="services2-photo" src={ServicesIcon6} alt="IT help"/>
                        </div>
                        <div className={`services2-text-wrapper moved-right ${inViewElements.includes(document.querySelectorAll(".services2-outer-wrapper")[5]) ? "slide-in-right" : "" }`}>
                            <p className="services2-text">Pomogę w przypadku problemów natury informatycznej dotyczących systemu operacyjnego, działania programów czy instalacji sprzętu.</p>
                        </div>
                    </div>
                    <svg style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }} viewBox="0 0 218.377 11.4935">
                        <g>
                            <path className="services-svg" d="M218.377 0c-2.56866,0.610332 -21.1535,3.22447 -23.3715,3.51891 -30.2256,4.0125 -52.2226,5.23764 -84.1455,3.93119 -18.752,-0.767531 -37.0929,-2.26404 -55.3803,-3.54986 -13.6034,-0.956359 -27.7268,-1.87919 -41.4215,-2.70496 -4.59541,-0.277066 -9.47264,-0.624586 -14.0579,-0.840293l0 11.1386 218.377 0 0 -11.4935z"/>
                        </g>
                    </svg>
                </div>
                <div id="services3-outer-container">
                    <div id="services3-outer-wrapper">
                        <div id="services3-left-wrapper">
                            <img id="services3-image" src={QuestionMark} alt="question"/>
                        </div>
                        <div id="services3-right-wrapper">
                            <Accordion bordered>
                                <Accordion.Panel header="Domena" eventKey={1}>
                                    <p className="accordion-description">Domena to unikalny adres, pod którym jest widoczna strona w internecie. Składa się on z nazwy np. "youtube" oraz z rozszerzenia np. "com". Domeny dzielą się na kilka rodzajów - najpopularniejsze to globalne(wspomniany wcześniej "com" oraz "net", krajowe(w Polsce jest to "pl", a także regionalne(np. "gniezno.pl").</p>
                                </Accordion.Panel>
                                <Accordion.Panel header="Hosting" eventKey={2}>
                                    <p className="accordion-description">Hosting jest to usługa udostępniania przestrzeni na własnych serwerach, czyli bardzo mocnych komputerach przeznaczonych do ciągłej pracy. Hostingodawca udostępnia specjalne oprogramowanie i przestrzeń dyskową, na której można umieścić wszystkie pliki wchodzące w skład strony internetowej.</p>
                                </Accordion.Panel>
                                <Accordion.Panel header="Grafika rastrowa" eventKey={3}>
                                    <p className="accordion-description">Grafika rastrowa to rodzaj obrazu, w którym całość przedstawiona jest za pomocą matrycy punktów(pikseli). Jest to szczególnie widoczne, kiedy użyjemy bardzo dużego powiększenia pliku graficznego. Z tego powodu grafiki rastrowej nie można powiększać bez utraty jakości. Najpopularniejszymi formatami plików rastrowych są pliki "jpg", "png", a także "gif".</p>
                                </Accordion.Panel>
                                <Accordion.Panel header="Grafika wektorowa" eventKey={4}>
                                    <p className="accordion-description">Grafika wektorowa to drugi podstawowy rodzaj grafiki komputerowej. W przeciwieństwie do obrazów rastrowych całość jest opisana za pomocą figur geometrycznych(połączonych krzywych). Dzięki takiej konstrukcji obraz można dowolnie zwiększać bez jakiejkolwiek utraty jakości. Grafika wektorowa jest głównie stosowana w druku.</p>
                                </Accordion.Panel>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};