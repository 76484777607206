import React, { useState, useEffect, useRef } from "react";
//import { UserContext, Axios } from './UserContext';

import IntroVideo from "../video/video.mp4";

import FlipIcon1 from "../images/icon1.webp";
import FlipIcon2 from "../images/icon2.webp";
import FlipIcon3 from "../images/icon3.webp";
import FlipIcon4 from "../images/icon4.webp";
import FlipIcon5 from "../images/icon5.webp";
import FlipIcon6 from "../images/icon6.webp";
import FlipBackground1 from "../images/flip-back1.webp";
import FlipBackground2 from "../images/flip-back2.webp";
import FlipBackground3 from "../images/flip-back3.webp";
import FlipBackground4 from "../images/flip-back4.webp";
import FlipBackground5 from "../images/flip-back5.webp";
import FlipBackground6 from "../images/flip-back6.webp";

import ProgrammingList from "../images/programming.webp";
import GraphicsList from "../images/graphics.webp";

import {ReactComponent as GraphicsListSVG} from '../images/svg/graphics.svg';
import {ReactComponent as ProgrammingSVG} from '../images/svg/programming.svg';

import CustomerLogo1 from "../images/customer-logo1.webp";
import CustomerLogo2 from "../images/customer-logo2.webp";
import CustomerLogo3 from "../images/customer-logo3.webp";
import CustomerLogo4 from "../images/customer-logo4.webp";
import CustomerLogo5 from "../images/customer-logo5.webp";
import CustomerLogo6 from "../images/customer-logo6.webp";
import CustomerLogo7 from "../images/customer-logo7.webp";
import CustomerLogo8 from "../images/customer-logo8.webp";

import Letter from "./Letter";

export default function Main(){

    // Zmiana tekstów - strona główna

    const [animationLoop, setAnimationLoop] = useState(false);

    const [animationText, setAnimationText] = useState({current: 0, first: "Kreatywne pomysły", second: "Oryginalne rozwiązania"});

    const animationTextRef = useRef(animationText);

    useEffect(() => {

        if(animationLoop){

            const texts = [
                {current: 0, first: "Kreatywne pomysły", second: "Oryginalne rozwiązania"},
                {current: 1, first: "Zorientowanie na klienta", second: "Dbałość o szczegóły"},
                {current: 2, first: "Kompleksowa obsługa", second: "Od projektu po realizację"}
            ]

            const length = texts.length;

            const current_object = animationTextRef.current;

            let current = current_object.current;

            let next;

            if(current === (length - 1)){
                next = 0;
            } else {
                next = current + 1;
            }

            setAnimationText(texts[next]);

            setAnimationLoop(false);

        }

    },[animationLoop]);

    useEffect(() => {

        animationTextRef.current = animationText;

    }, [animationText]);

    function scrollToDiv(id){

        let element = document.getElementById(id);

        if(element){

            element.scrollIntoView({ behavior: 'smooth' });

        }

    }

    useEffect(() => {

        if ('scrollRestoration' in window.history) {

                window.history.scrollRestoration = 'manual';

            return () => {

                window.history.scrollRestoration = 'auto';
                
            };
        }

    }, []);

    const [touch, setTouch] = useState({div_1: false, div_2: false, div_3: false, div_4: false, div_5: false, div_6: false});

    return (
        <>
            <div className="page">
                <div id="intro-outer-container">
                    <div id="intro-outer-wrapper">
                        <div id="intro-video-container">
                            <video id="intro-video" autoPlay loop muted>
                                <source src={IntroVideo} type='video/mp4; codecs="mp4a.40.2"'/>
                            </video>
                        </div>
                        <div id="intro-text-container">
                            <div id="intro-text-wrapper">
                                <p id="intro-heading">Wykorzystaj potęgę internetu</p>
                                <p className="intro-text">Dotrzyj do milionów klientów w Polsce i na całym świecie tworząc swoją wizytówkę w sieci lub korzystając z reklamy w internecie</p>
                                <p id="intro-link" onClick={() => scrollToDiv('main3-outer-container')} className="intro-link ice">Poznaj szczegóły</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="main1-outer-container">
                    <div id="main1-outer-wrapper">
                        <p id="main1-text-front" className="main1-text">Witaj!</p>
                        <p className="main1-text">Nazywam się Paweł Pokrywka i tworzenie to moja pasja. Z chęcią zaprojektuję i wykonam dla Ciebie:</p>
                        <ul id="main1-list">
                            <li>stronę lub sklep internetowy</li>
                            <li>logo</li>
                            <li>baner reklamowy</li>
                            <li>ulotki, foldery reklamowe</li>
                            <li>dowolne grafiki do wydruku/online</li>
                        </ul>
                        <div className="main1-link-wrapper">
                            <a href="https://pp-start.pl/uslugi" className="main1-link">Sprawdź ofertę</a>
                        </div>
                    </div>
                </div>
                <div id="main2-outer-container">
                    <div id="main2-top-transit" className="main2-transit"></div>
                    <div id="main2-wrapper">
                        <h1 className="main2-heading" id="main2-h1" onAnimationIteration={() => setAnimationLoop(true)}>{animationText.first}</h1>
                        <h2 className="main2-heading" id="main2-h2">{animationText.second}</h2>
                    </div>
                    <div id="main2-bottom-transit" className="main2-transit"></div>
                </div>
                <div id="main3-outer-container">
                    <div id="main3-outer-wrapper">
                        <div className="violet"></div>
                        <div className="skew-cc"></div>
                        <div className="main3-inner-wrapper" onTouchStart={() => setTouch({...touch, div_1: true})} onTouchEnd={() => setTouch({...touch, div_1: false})}>
                            <div className="flex-column">
                                <div className="flip-box">
                                    <div className={touch.div_1 ? "flip-box-inner touched" : "flip-box-inner"}>
                                        <div className="flip-box-front">
                                            <div>
                                                <img className="main3-icon" src={FlipIcon1} alt="icon1"/>
                                                <p className="main3-text">Strony i sklepy internetowe</p>
                                            </div>
                                        </div>
                                        <div className="flip-box-back flip-box-back-white">
                                            <img className="main3-back" src={FlipBackground1} alt="flip-back1"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main3-text-wrapper">
                                <p className="main3-side-text">Wykonam dla Ciebie estetyczną i funkcjonalną stronę internetową, która z miejsca stanie się Twoją wirtualną wizytówką.</p>
                            </div>
                        </div>
                        <div className="skew-c"></div>
                        <div className="violet">
                            <div className="main3-inner-wrapper" onTouchStart={() => setTouch({...touch, div_2: true})} onTouchEnd={() => setTouch({...touch, div_2: false})}>
                                <div className="flex-column">
                                    <div className="flip-box">
                                        <div className={touch.div_2 ? "flip-box-inner touched" : "flip-box-inner"}>
                                            <div className="flip-box-front">
                                                <div>
                                                    <img className="main3-icon" src={FlipIcon2} alt="icon2"/>
                                                    <p className="main3-text white">Aplikacje web i android</p>
                                                </div>
                                            </div>
                                            <div className="flip-box-back flip-box-back-violet">
                                                <img className="main3-back" src={FlipBackground2} alt="flip-back2"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="main3-text-wrapper">
                                    <p className="main3-side-text white">Napiszę i uruchomię dla Ciebie aplikację webową dostępną w przeglądarce oraz na telefonach z systemem android.</p>
                                </div>
                            </div>
                        </div>
                        <div className="skew-cc"></div>
                        <div className="main3-inner-wrapper" onTouchStart={() => setTouch({...touch, div_3: true})} onTouchEnd={() => setTouch({...touch, div_3: false})}>
                            <div className="flex-column">
                                <div className="flip-box">
                                    <div className={touch.div_3 ? "flip-box-inner touched" : "flip-box-inner"}>
                                        <div className="flip-box-front">
                                            <div>
                                                <img className="main3-icon" src={FlipIcon3} alt="icon3"/>
                                                <p className="main3-text">Grafika komputerowa</p>
                                            </div>
                                        </div>
                                        <div className="flip-box-back flip-box-back-white">
                                            <img className="main3-back" src={FlipBackground3} alt="flip-back3"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main3-text-wrapper">
                                <p className="main3-side-text">Przygotuję dla Ciebie logo, baner czy też jakąkolwiek inną grafikę na stronę internetową lub firmowe dokumenty.</p>
                            </div>
                        </div>
                        <div className="skew-c"></div>
                        <div className="violet">
                            <div className="main3-inner-wrapper" onTouchStart={() => setTouch({...touch, div_4: true})} onTouchEnd={() => setTouch({...touch, div_4: false})}>
                                <div className="flex-column">
                                    <div className="flip-box">
                                        <div className={touch.div_4 ? "flip-box-inner touched" : "flip-box-inner"}>
                                            <div className="flip-box-front">
                                                <div>
                                                    <img className="main3-icon" src={FlipIcon4} alt="icon4"/>
                                                    <p className="main3-text white">Reklamy internetowe</p>
                                                </div>
                                            </div>
                                            <div className="flip-box-back flip-box-back-violet">
                                                <img className="main3-back" src={FlipBackground4} alt="flip-back4"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="main3-text-wrapper">
                                    <p className="main3-side-text white">Zaprojektuję i wykonam dla Ciebie zarówno statyczną jak i dynamiczną grafikę, dzięki której zareklamujesz swoje usługi w internecie.</p>
                                </div>
                            </div>
                        </div>
                        <div className="skew-cc"></div>
                        <div className="main3-inner-wrapper" onTouchStart={() => setTouch({...touch, div_5: true})} onTouchEnd={() => setTouch({...touch, div_5: false})}>
                            <div className="flex-column">
                                <div className="flip-box">
                                    <div className={touch.div_5 ? "flip-box-inner touched" : "flip-box-inner"}>
                                        <div className="flip-box-front">
                                            <div>
                                                <img className="main3-icon" src={FlipIcon5} alt="icon5"/>
                                                <p className="main3-text">Hosting i domena</p>
                                            </div>
                                        </div>
                                        <div className="flip-box-back flip-box-back-white">
                                            <img className="main3-back" src={FlipBackground5} alt="flip-back5"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main3-text-wrapper">
                                <p className="main3-side-text">Wybiorę dla Ciebie optymalny hosting(miejsce na stronę internetową), a także zajmę się zakupem domeny(adres pod jakim strona będzie widoczna w sieci).</p>
                            </div>
                        </div>
                        <div className="skew-c"></div>
                        <div className="violet">
                            <div className="main3-inner-wrapper" onTouchStart={() => setTouch({...touch, div_6: true})} onTouchEnd={() => setTouch({...touch, div_6: false})}>
                                <div className="flex-column">
                                    <div className="flip-box">
                                        
                                        <div className={touch.div_6 ? "flip-box-inner touched" : "flip-box-inner"}>
                                            <div className="flip-box-front">
                                                <div>
                                                    <img className="main3-icon" src={FlipIcon6} alt="icon6"/>
                                                    <p className="main3-text white">Zaawansowane skrypty</p>
                                                </div>
                                            </div>
                                            <div className="flip-box-back flip-box-back-violet">
                                                <img className="main3-back" src={FlipBackground6} alt="flip-back6"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="main3-text-wrapper">
                                    <p className="main3-side-text white">Dzięki zastosowaniu niestandardowych rozwiązań i skryptów masz pewność, że na Twojej stronie nie zabraknie żadnej funkcjonalności, której oczekujesz.</p>
                                </div>
                            </div>
                        </div>
                        <div className="skew-cc"></div>
                    </div>
                </div>
                <div className="main4-outer-container">
                    <div className="main4-outer-wrapper main4-other">
                        <div className="main4-text-wrapper">
                            <div className="main4-text">Dzięki znajomości wielu języków oraz środowisk programowania stworzę dla Ciebie dokładnie taką stronę internetową, której potrzebujesz.</div>
                        </div>
                        <div className="main4-image-wrapper">
                            <img className="main4-image" src={ProgrammingList} alt="programming-list"/>
                            <ProgrammingSVG/>
                        </div>
                    </div>
                    <div className="main4-outer-wrapper">
                        <div className="main4-image-wrapper">
                            <img className="main4-image" src={GraphicsList} alt="graphics-list"/>
                            <GraphicsListSVG/>
                        </div>
                        <div className="main4-text-wrapper">
                            <div className="main4-text">Zaprojektuję i wykonam dla Ciebie grafiki wektorowe przeznaczone do druku jak i rastrowe, które będzie można zamieścić w internecie.</div>
                        </div>
                    </div>
                </div>
                <div id="main5-outer-container">
                    <div id="main5-outer-wrapper">
                        <div id="main5-top-wrapper">
                            <h2 id="main5-header">Zacznijmy tworzyć!</h2>
                            <p className="main5-sub-header">Napisz do mnie</p>
                            <p className="main5-text">Wypełnij formularz i wyślij mi wiadomość.<br></br> Czekam na list od Ciebie!</p>
                        </div>
                        <Letter/>
                        <p className="main5-sub-header">Zadzwoń</p>
                        <p className="main5-text">Znajdziesz mnie pod numerem:<br></br><a href="tel:662890561" id="main5-phone">662 890 561</a></p>
                    </div>
                </div>
                <div id="main6-outer-container">
                    <div id="main6-outer-wrapper">
                        <div id="main6-header-wrapper">
                            <p id="main6-header">Zaufali mi między innymi</p>
                        </div>
                        <div id="main6-logo-wrapper">
                            <div className="main6-logo-container">
                                <img className="main6-customer-logo" src={CustomerLogo1} alt="customer-logo-1"/>
                            </div>
                            <div className="main6-logo-container">
                                <img className="main6-customer-logo" src={CustomerLogo2} alt="customer-logo-2"/>
                            </div>
                            <div className="main6-logo-container">
                                <img className="main6-customer-logo" src={CustomerLogo3} alt="customer-logo-3"/>
                            </div>
                            <div className="main6-logo-container">
                                <img className="main6-customer-logo" src={CustomerLogo4} alt="customer-logo-4"/>
                            </div>
                            <div className="main6-logo-container">
                                <img className="main6-customer-logo" src={CustomerLogo5} alt="customer-logo-5"/>
                            </div>
                            <div className="main6-logo-container">
                                <img className="main6-customer-logo" src={CustomerLogo6} alt="customer-logo-6"/>
                            </div>
                            <div className="main6-logo-container">
                                <img className="main6-customer-logo" src={CustomerLogo7} alt="customer-logo-7"/>
                            </div>
                            <div className="main6-logo-container">
                                <img className="main6-customer-logo" src={CustomerLogo8} alt="customer-logo-8"/>
                            </div>
                        </div>
                        <div id="main6-footer-wrapper">
                            <p id="main6-footer">Nie czekaj i też dołącz do grona moich zadowolonych klientów.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};